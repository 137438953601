<template>
  <div class="row playground-row">
    <div class="playground col-md-9">
      <div class="playground__container">
        <!-- insert map -->
        <!-- using a responsive iframe -->
        <div class="responsive-map-container">
          <!-- place the iframe code between here... -->
          <object
            :data="gamesLink"
            width="100%"
            height="100%"
          />
          <!-- <iframe
            :src="gamesLink"
            width="600"
            height="450"
            frameborder="0"
            style="border:0"
          ></iframe> -->
          <!-- ... and here -->
        </div>
        <!-- /insert map -->

        <!-- <div class="playground__container__items__categories">
          <AppCategoryItem
            v-for="(category, indexCategories) in categories"
            :key="indexCategories"
            :name="category.name"
            :iconSrc="category.iconSrc"
            :bgColor="category.bgColor"
            :color="category.color"
            class="playground__container__items__categories__item"
            @click.native="selectCategory(indexCategories)"
          />
        </div>
        <div class="playground__container__items__products">
          <AppPlaygroundGame
            v-for="(game, index) in this.games"
            :key="index"
            :id="game.id"
            :name="game.name"
            :image="game.image"
            style="display: none;"
          />
        </div> -->
      </div>
    </div>
    <div class="col-md-3">
      <div class="playground__sidebar">
        <div class="playground__sidebar__spendWallet">
          <AppPlaygroundWallet :points="getSecretStorePoints" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppPlaygroundWallet from "../../components/AppPlaygroundWallet";
// import AppCategoryItem from "@/components/AppCategoryItem";
// import AppPlaygroundGame from "@/components/AppPlaygroundGame";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import config from "../../config";

export default {
  components: {
    AppPlaygroundWallet,
    // AppCategoryItem,
    // AppPlaygroundGame,
  },
  data() {
    return {
      games: [],
      gamesIndex: 0,
      walletPoints: 1200,
      selectedCategoryGamesIndex: 0,
      categories: [
        {
          id: 0,
          name: "All skills",
          iconSrc: "test_icon.png",
          bgColor: "#36D5F2",
        },
        {
          id: 1,
          name: "Mental",
          iconSrc: "test_icon.png",
          bgColor: "#FFFFFF",
          games: [
            {
              id: 1,
              name: "Quick Math Test",
              image: "game_image.png",
            },
            {
              id: 2,
              name: "Card memory",
              image: "game_image.png",
            },
          ],
        },
        {
          id: 2,
          name: "Visual",
          iconSrc: "test_icon.png",
          bgColor: "#FFFFFF",
          games: [
            {
              id: 3,
              name: "Sonik Hangman",
              image: "game_image.png",
            },
          ],
        },
      ],
    };
  },
  created() {
    const user = {
      userId: localStorage.getItem("children_user_id"),
      token: localStorage.getItem("children_token"),
      family_id_check: localStorage.getItem("children_family_id"),
    };
    this.setupUser(user);
    this.fetchSecretStore();
  },
  mounted() {
    this.selectedCategoryGamesIndex = 0;

    this.games = this.categories[1].games;
  },
  computed: {
    ...mapGetters(["getSecretStorePoints"]),
    gamesLink() {
      const token = localStorage.getItem("children_token");
      const userId = localStorage.getItem("children_user_id");

      return (
        config.GAMES_URL + `?token=${token}&user_id=${userId}&playground=true`
      );
    },
  },
  methods: {
    ...mapActions(["fetchSecretStore", "setupUser"]),

    selectCategory(index) {
      var i;

      if (index == 0) {
        var j;
        this.categories[0].bgColor = "#36D5F2";

        for (i = 1; i < this.categories.length; i++) {
          for (j = 0; j < this.categories[i].games.length; j++) {
            this.games[this.gamesIndex++] = this.categories[i].games[j];
          }
        }
      }
      for (i = 1; i < this.categories.length; i++) {
        if (i == index) {
          this.categories[0].bgColor = "#FFFFFF";
          this.categories[i].bgColor = "#36D5F2";
          this.games = this.categories[i].games;
          this.selectedCategoryGamesIndex = index;
        } else {
          this.categories[i].bgColor = "#FFFFFF";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comingSoon {
  
  font-size: 30px;
  // margin: auto;
  margin-top: 20px;
  color: #ff4f79;
}

.playground__container__items__categories {
  display: none !important;
}

.playground {
  // display: grid;
  // grid-template-columns: 10fr 3fr;
  height: 100%;

  &__container {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &__items {
      width: 100%;
      overflow-y: auto;
      overflow-x: auto;

      &__title {
        padding-top: 30px;
        margin: auto;
        
        font-size: 50px;
        line-height: 24px;
        color: #7a615a;
      }

      &__top {
        &__title {
          padding-top: 40px;
          margin-left: 10px;
          
          font-size: 40px;
          line-height: 24px;
          color: #7a615a;
        }
      }

      &__categories {
        width: 100%;
        height: 180px;
        margin-top: 30px;
        border-radius: 50px;
        background-color: #f8f5ed;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-auto-flow: column;
        grid-auto-columns: minmax(250px, 1fr);
        overflow-x: auto;

        &__item {
          padding: auto;
          height: 130px;
          width: 250px;
          margin-left: 20px;
          margin-right: 10px;
          background-color: white;
          margin-top: 20px;
          margin-bottom: 20px;
          border-radius: 50px;
        }
      }
      &__products {
        margin-top: 30px;
        width: 100%;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        overflow-x: hidden;
      }
    }
  }

  &__sidebar {
    height: 100%;
    grid-gap: 40px;
    // margin-left: 20px;
    // margin-right: 10px;
    display: grid;
    grid-template-rows: 1fr;
  }
}

.soon {
  display: none;
}

@media screen and (max-width: 800px) {
  .playground__sidebar {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 0px;
  }
  .playground {
    background: #36d5f2;
    color: white;
    text-align: center;
  }
  .soon {
    display: block;
  }
  .soon h1 {
    margin-bottom: 15px;
  }
  .soon p {
    padding: 0 60px;
  }
  .playgroundItems__top {
    display: grid;
    grid-template-columns: 1fr;
  }
  .playground {
    display: grid;
    grid-template-columns: 1fr;

    &__container {
      height: 100vh;
    }
  }
  .playgroundItems__products {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
  .playgroundItems {
    grid-template-rows: 1fr;
  }
  .playground__container__items__title {
    margin-top: 10px;
  }
  .playground__container__items__products {
    display: grid;
    grid-template-columns: 1fr;
  }
  .playground__container__items__categories {
    display: none;
  }
  .playground__sidebar {
    display: none;
  }
  .playground__container {
    height: calc(100vh - 16vh);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .playground__container__items {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .playground__container__items__top__title {
    margin: 0;
    text-align: center;
    font-size: 20px;
    padding: 20px;
  }
  .playground {
    display: block;
  }
  .playground__container__items__categories__item {
    height: 75px;
    margin-bottom: 0;
  }
  .playground__container__items__categories {
    height: 115px;
  }
  .playground__sidebar {
    display: block;
    margin: 15px 15px 25px 15px;
  }
  .playground__container__items__products {
    display: block;
    overflow: hidden;
    text-align: center;
  }
}

.responsive-map-container {
  position: relative;
  padding-bottom: 76%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  border-radius: 20px;
}

.responsive-map-container iframe,
.responsive-map-container object,
.responsive-map-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
