<template>
  <div class="playgroundWallet">
    <div class="playgroundWallet__title">
      Your Sonik Points
    </div>
    <div class="playgroundWallet__points">
      {{ points }}
      <div class="playgroundWallet__points__subtitle">
        Total
      </div>
    </div>
    <div class="playgroundWallet__bottom">
      <div class="playgroundWallet__bottom__title">
        Go spend your points
      </div>
      <div
        class="playgroundWallet__bottom__circle"
        @click="openModal"
      >
        <div class="playgroundWallet__bottom__circle__plus" />
      </div>
    </div>
    <AppSonikStore
      v-if="modal == true || $store.state.gamesResponse == true"
      :points="points"
      @close="closeModal"
    />
  </div>
</template>

<script>
import AppSonikStore from "../components/AppSonikStore";

export default {
  components: {
    AppSonikStore,
  },
  props: {
    points: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      modal: false,
      playgroundModal: false,
    };
  },
  methods: {
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.$store.state.gamesResponse = false;
    },
  },
};
</script>

<style lang="scss">
.playgroundWallet {
  height: 100%;
  width: 100%;
  background-image: url("../assets/images/playgroundWallet.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  &__title {
    padding: 10px;

    font-size: 20px;
    line-height: 24px;
    color: white;
    margin-left: auto;
    margin-right: auto;
  }

  &__points {
    font-size: 60px;
    line-height: 24px;
    color: white;
    margin: auto;
    margin-top: 50px;
    text-align: center;

    &__subtitle {
      font-size: 20px;
      line-height: 24px;
      color: white;
      padding: 20px;
      text-align: center;
    }
  }

  &__bottom {
    float: bottom;

    &__title {
      color: white;
      font-size: 20px;
      line-height: 21px;
      text-align: center;

      padding: 10px;
    }

    &__circle {
      height: 140px;
      width: 140px;
      margin: auto;
      background-image: url("../assets/images/arrow_plus_button_red.png") !important;
    }
  }
}
</style>
